import axios from 'axios'
import { LOGIN, LOGOUT, SETSTATE } from './constrant'
import initState from './inistate'

export default function App_reducer(preState=initState,action){

    const { type, data } = action

    switch (type) {
            //采用JS原生cookie方法存储,cookie.save会出现莫名BUG
        case LOGIN:
            if(data.remember){
                localStorage.setItem('userId',JSON.stringify(data));// 存token
                return {...preState,...data}
            }
            else{
                sessionStorage.setItem('userId',JSON.stringify(data)); // 存token 
                return {...preState,...data}
            }
            //注销清除session和cookie
        case LOGOUT: 
            document.cookie = "userId=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            document.title = '创芯智慧云管理平台';
            sessionStorage.removeItem('userId');
            localStorage.removeItem('userId');
          //  localStorage.removeItem('state');
            return {...preState,isPass:false,remember:false,UserID:null,error:false,networkState:'stop'}

            //redux存储全局数据方法setState
        case SETSTATE:
           // localStorage.setItem('state',JSON.stringify({...preState,...data}));
            return {...preState,...data}
            // 第一次登录及每次刷新页面执行一次,及时更新session
        default:
            if(localStorage.getItem('userId')!==null){
                axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('userId')).token
            }
            else if(sessionStorage.getItem('userId')!==null){
                axios.defaults.headers.common['Authorization'] = JSON.parse(sessionStorage.getItem('userId')).token
            }
            return preState
    }

} 