// 每次刷新登录状态将从本地会话中读取,若存在cookie则从cookie中读取UserID,若只存在session则从session从读取
let initState = {
    isPass: false,
    remember: false,
    UserID: null
}
if (localStorage.getItem('userId') !== null) {
    initState = JSON.parse(localStorage.getItem('userId'))
} else if (sessionStorage.getItem('userId') !== null) {
    initState = JSON.parse(sessionStorage.getItem('userId'))
}

initState = {
    ...initState, // 初始用户状态
    networkState: 'stop', // 循环网络请求状态
    isEditingLayout: false, // 布局编辑状态
    isEditingComponents: false, // 组件编辑状态
    isShowId: false, // 选择器ID显示状态
    tabActiveKey: '', // 当前激活的标签页标题
    resetLayout: null, // 布局重置信号
}
// 初始化state树
export default initState